@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400;500;700;800;900&display=swap');

/* @font-face {
  font-family: myFirstFont;
  src: url(Assests/Dancing_Script/DancingScript-VariableFont_wght.ttf);
} */
.message {
	/* font-family: "Dancing Script", cursive; */
	/* font-family: 'Berkshire Swash', cursive; */
	/* font-family: 'Lobster', cursive; */
	/* font-family: 'Playball', cursive; */
	font-family: myFirstFont;
}
/* .image {
  background-image: url("./Images/Hero\ 3.png");
} */

* {
	font-family: 'Roboto Slab' !important;
}

.marquee {
	position: relative;
	width: 100vw;
	max-width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	overflow: hidden;
}

.track {
	position: absolute;
	white-space: nowrap;
	will-change: transform;
	animation: marquee 18s linear infinite;
}

@keyframes marquee {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-50%);
	}
}

@media (max-width: 500) {
	.slider-slide {
		width: 100%;
	}
}

.rupee1 {
	border: 2px solid red;
}
.iiz__img {
	height: 375px !important;
}
@media (max-width: 768px) {
	.iiz__img {
		height: 250px !important;
	}
}

::-webkit-scrollbar {
	display: none;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 0.2rem;
	overflow-x: hidden;
}

.App {
	font-family: sans-serif;
	text-align: center;
}

.tracker {
	display: flex;
	justify-content: center;
}

.steps {
	list-style: none;
	display: flex;
	padding: 0;
}

.step {
	width: 40px;
	height: 40px;
	border: 2px solid #ccc;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 10px;
	font-weight: bold;
	font-size: 18px;
	background-color: #fff;
}

.step.active {
	border-color: #000;
	background-color: #000;
	color: #fff;
}

.croller {
	width: 35px;
	height: 100%;
	background-color: #f1f1f1;
}

.perimeter {
	max-width: 1024px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
}
.image {
	flex: 0 0 500%;
}
.copy {
	padding: 20px;
	font-family: sans-serif;
}
@media (min-width: 415px) {
	.perimeter {
		flex-direction: row;
	}
	.image {
		flex: 0 0 500%;
	}
}
@media (min-width: 800px) {
	.image {
		flex: 0 0 500%;
	}
}

.lazy-load-image-background.blur.lazy-load-image-loaded img {
	height: 100%;
	width: 100%;
}
