@tailwind base;
@tailwind components;
@tailwind utilities;

@import './fonts.css';

* {
	font-family: Robot-Slab;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

body {
	width: 100vw;
	overflow-x: hidden;
	background: #e3e3e3;
	/* user-select: none; */
}

.scrollbar-hidden::-webkit-scrollbar {
	display: none;
}

.scrollbar::-webkit-scrollbar {
	height: 6px;
	width: 6px;
	background-color: black;
}

.scrollbar::-webkit-scrollbar-thumb {
	background-color: #d3d3d3;
	height: 1px;
	border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-track {
	background-color: #f5f5f5;
	border-radius: 1px;
}

.bgColor {
	background-color: transparent !important;
	box-shadow: none !important;
}

.icon {
	color: black !important;
}

.button {
	color: white !important;
	background-color: black !important;
	border-radius: 0px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.react-transform-wrapper,
.react-transform-component,
.react-transform-component > div {
	min-height: 100vh;
	height: 100% !important;
	width: 100% !important;
}

.slick-list {
	height: 100%;
}

.slick-track {
	height: 100%;
}

.slick-slide > div,
.slick-slide > div > div,
.slick-slide > div,
.slick-slide > div > div > img {
	height: 100% !important;
}

.slick-slide > div,
.slick-slide > div > div > img {
	width: 100% !important;
	object-fit: cover;
}

.about-slider-container .slick-dots {
	bottom: unset;
	top: 85%;
}

.loader-27 {
	width: 100px;
	height: 100px;
	display: inline-block;
	position: relative;
}

.loader-27::after,
.loader-27::before {
	content: '';
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background: #fff;
	position: absolute;
	left: 0;
	top: 0;
	-webkit-animation: animloader27 2s ease-in-out infinite;
	animation: animloader27 2s ease-in-out infinite;
}

.loader-27::after {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.react-datepicker__input-container input {
	outline: none;
}

@-webkit-keyframes animloader27 {
	0%,
	100% {
		transform: scale(0);
		opacity: 1;
	}

	50% {
		transform: scale(1);
		opacity: 0;
	}
}

@keyframes animloader27 {
	0%,
	100% {
		transform: scale(0);
		opacity: 1;
	}

	50% {
		transform: scale(1);
		opacity: 0;
	}
}

@keyframes slideInLeft {
	from {
		transform: translateX(-100%);
	}

	to {
		transform: translateX(0);
	}
}

/* CSS for heading animation */
@keyframes slideInRight {
	from {
		transform: translateX(100%);
	}

	to {
		transform: translateX(0);
	}
}

/* slick carousel css */
@media not screen and (max-width: 899px) {
	/* General styles for slick-active.slick-current > div > div */
	.slick-active.slick-current > div > div {
		text-align: left;
		color: white;
	}

	/* Styles for slick-dots */
	.slick-dots {
		left: 0;
		right: 0;
		text-align: center;
		list-style: none;
		padding: 0;
	}

	.home-slider .slick-dots {
		bottom: 170px;
		height: 0;
	}

	.slick-dots li {
		display: inline-block;
		height: 0;
		color: transparent;
	}

	.slick-dots li button {
		width: 8px;
		height: 8px;
		border: none;
		background: gray;
		cursor: pointer;
		color: transparent;
	}

	.slick-dots li.slick-active button {
		background: #ffffff;
		width: 32px;
		height: 13px;
		margin-left: -10px;
	}

	.slick-dots li button:before {
		width: 0;
		height: 0;
		color: transparent !important;
	}

	.slick-dots li button::after {
		width: 0;
		height: 0;
	}

	/* About-slider specific styles */
	.about-slider .slick-active.slick-current > div > div {
		padding-left: 10rem;
		padding-right: 2rem;
	}

	.about-slider .slick-active > div > div {
		padding-right: 10rem;
	}

	.about-slider .slick-dots > div > div {
		display: none;
	}
}

@media not screen and (max-width: 899px) {
	.order-slider .slick-dots {
		position: static;
		display: flex !important;
		height: 8px;
		justify-content: center;
		gap: 12px;
	}

	.order-slider .slick-dots li {
		display: inline-block;
		color: transparent;
		padding: 0;
		margin: 0;
		height: 0;
	}

	.order-slider .slick-dots li button {
		width: 16px;
		height: 8px;
		border: none;
		padding: 0;
		border-radius: 2px;
		background: var(--gray-300, #d1d5db);
		cursor: pointer;
	}

	.order-slider .slick-dots li.slick-active button {
		width: 16px;
		height: 8px;
		border-radius: 2px;
		background: var(--black, #000);
		margin: 0;
	}
}

@media not screen and (max-width: 400px) {
	.order-slider .slick-dots {
		position: static;
		display: flex !important;
		height: 6px !important;
		justify-content: center;
		gap: 8px;
	}

	.order-slider .slick-dots li {
		display: inline-block;
		color: transparent;
		padding: 0;
		margin: 0;
		height: 0;
	}

	.order-slider .slick-dots li button {
		width: 8px !important;
		height: 8px !important;
		border: none;
		border-radius: 2px;
		background: var(--gray-300, #d1d5db);
		cursor: pointer;
	}

	.order-slider .slick-dots li.slick-active button {
		width: 16px !important;
		height: 8px !important;
		border-radius: 2px;
		background: var(--black, #000);
		margin: 0;
	}
}

@media only screen and (max-width: 400px) {
	.slick-active.slick-current > div > div {
		text-align: left;
	}

	.slick-dots li.slick-active button:before {
		opacity: 0.75;
		display: none;
		color: white;
	}

	.order-slider .slick-dots {
		position: absolute;
		bottom: 2px;
		left: 0;
		right: 0;
		text-align: center;
		list-style: none;
		padding: 0;
		display: none;
		height: 8px;
	}

	.slick-dots {
		position: absolute;
		bottom: 85px;
		left: 0;
		right: 0;
		text-align: center;
		list-style: none;
		padding: 0;
		display: none;
		height: 0;
	}

	.slick-dots li {
		display: inline-block;
		height: 0;
	}

	.slick-dots li button {
		width: 4px;
		height: 0px;
		border: none;
		background: gray;
		cursor: pointer;
		border-radius: 2px;
	}

	.slick-dots li.slick-active button {
		background: #ffffff;
		width: 16px;
		height: 6px !important;
		border-radius: 2px;
	}

	.order-slider .slick-dots li button {
		width: 6px;
		height: 6px;
		border: none;
		background: gray;
		cursor: pointer;
		padding: 0 !important;
		border-radius: 2px;
	}

	.order-slider .slick-dots li.slick-active button {
		background: black !important;
		width: 16px;
		height: 8px;
		border-radius: 2px;
	}

	.about-slider .slick-active.slick-current > div > div {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.slick-dots li button:before {
		width: 0;
		height: 0;
		color: transparent !important;
	}

	.about-slider .slick-active > div > div {
		padding-right: 10rem;
	}

	.about-slider .slick-dots > div > div {
		display: none;
		top: 50;
	}
}

@media only screen and (max-width: 400px) {
	.home-slider {
		top: 87%;
	}
}

/*  */
/* Common styles for all screen sizes */
.SamplePrevArrow-slider,
.SampleNextArrow-slider {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	z-index: 1;
}

/* Styles for screens with a maximum width of 786px */
@media only screen and (max-width: 786px) {
	.SamplePrevArrow-slider {
		left: 16px;
	}

	.SampleNextArrow-slider {
		right: 16px;
	}
}

/* Styles for screens with a maximum width of 2500px */
@media only screen and (min-width: 787px) and (max-width: 2500px) {
	.SampleNextArrow-slider {
		right: 96px;
	}

	.SamplePrevArrow-slider {
		left: 96px;
	}
}

/* Album Aerrows */
@media only screen and (max-width: 2500px) {
	.SampleNextArrow-Album {
		position: absolute;
		right: -50px;
		top: 125px;
		transform: translateY(-50%);
		cursor: pointer;
		z-index: 1;
	}

	.slick-slider {
		overflow: visible;
	}
}

@media only screen and (max-width: 2500px) {
	.SamplePrevArrow-Album {
		position: absolute;
		left: -50px;
		top: 125px;
		transform: translateY(-50%);
		cursor: pointer;
		z-index: 1;
	}

	.slick-slider {
		overflow: visible;
	}
}

@media only screen and (max-width: 800px) {
	.SampleNextArrow-Album,
	.SamplePrevArrow-Album {
		display: none;
	}

	.slick-slider {
		overflow: visible;
	}
}

@keyframes shinyLoader {
	0% {
		background-position: -64px 0;
	}

	to {
		background-position: 64px 0;
	}
}

.animation-shiny-button {
	animation: shinyLoader 10s linear infinite;
	background: linear-gradient(
			121.19deg,
			rgba(133, 239, 49, 0) 25.73%,
			hsla(0, 0%, 100%, 0.3) 45.27%,
			rgba(133, 239, 49, 0) 62.27%
		),
		#00000000;
}

@keyframes shinyLoader-main-frame {
	0% {
		background-position: -700px 0;
	}

	to {
		background-position: 700px 0;
	}
}

.animation-shiny-button-main-frame {
	animation: shinyLoader-main-frame 5s linear infinite;
	background: linear-gradient(
			121.19deg,
			rgba(133, 239, 49, 0) 25.73%,
			hsla(0, 0%, 100%, 0.35) 45.27%,
			rgba(133, 239, 49, 0) 62.27%
		),
		#00000000;
}

.react-datepicker-popper {
	z-index: 99 !important;
}

.magnify-container > div {
	width: 100% !important;
}

.magnify-container > div > img {
	height: 100% !important;
}
