@font-face {
  font-family: "BeVietnam-Bold"; /* Replace with your desired font family name */
  src: url("../public/assets/Fonts/be-vietnam/BeVietnam-Bold.ttf")
    format("truetype"); /* Path to your TTF file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BeVietnam-BoldItalic"; /* Replace with your desired font family name */
  src: url("../public/assets/Fonts/be-vietnam/BeVietnam-BoldItalic.ttf")
    format("truetype"); /* Path to your TTF file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BeVietnam-ExtraBold"; /* Replace with your desired font family name */
  src: url("../public/assets/Fonts/be-vietnam/BeVietnam-ExtraBold.ttf")
    format("truetype"); /* Path to your TTF file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BeVietnam-ExtraBoldItalic"; /* Replace with your desired font family name */
  src: url("../public/assets/Fonts/be-vietnam/BeVietnam-ExtraBoldItalic.ttf")
    format("truetype"); /* Path to your TTF file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BeVietnam-Italic"; /* Replace with your desired font family name */
  src: url("../public/assets/Fonts/be-vietnam/BeVietnam-Italic.ttf")
    format("truetype"); /* Path to your TTF file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BeVietnam-Light"; /* Replace with your desired font family name */
  src: url("../public/assets/Fonts/be-vietnam/BeVietnam-Light.ttf")
    format("truetype"); /* Path to your TTF file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BeVietnam-LightItalic"; /* Replace with your desired font family name */
  src: url("../public/assets/Fonts/be-vietnam/BeVietnam-LightItalic.ttf")
    format("truetype"); /* Path to your TTF file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BeVietnam-Medium"; /* Replace with your desired font family name */
  src: url("../public/assets/Fonts/be-vietnam/BeVietnam-Medium.ttf")
    format("truetype"); /* Path to your TTF file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BeVietnam-MediumItalic"; /* Replace with your desired font family name */
  src: url("../public/assets/Fonts/be-vietnam/BeVietnam-MediumItalic.ttf")
    format("truetype"); /* Path to your TTF file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BeVietnam-Regular"; /* Replace with your desired font family name */
  src: url("../public/assets/Fonts/be-vietnam/BeVietnam-Regular.ttf")
    format("truetype"); /* Path to your TTF file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BeVietnam-SemiBold"; /* Replace with your desired font family name */
  src: url("../public/assets/Fonts/be-vietnam/BeVietnam-SemiBold.ttf")
    format("truetype"); /* Path to your TTF file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BeVietnam-SemiBoldItalic"; /* Replace with your desired font family name */
  src: url("../public/assets/Fonts/be-vietnam/BeVietnam-SemiBoldItalic.ttf")
    format("truetype"); /* Path to your TTF file */
  font-weight: normal;
  font-style: normal;
}